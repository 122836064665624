<template>
    <TriggerChat v-if="!is_show_support_chat && current_user"/>
    <div v-if="is_show_support_chat && current_user" ref="chat_box" class="chat-box slide-in-right">
        <div class="chat-header">
            <div class="close-btn" @click="handle_close_chat_window">
                <i class="material-symbols-rounded" style="text-shadow:none;">highlight_off</i>
            </div>
            <div ref="minimize_btn" class="minimize-btn" @click="handle_minimize_chat_window">
                 <i class="material-symbols-rounded" style="text-shadow:none;">expand_more</i>
            </div>
            <p>תמיכה</p>
        </div>
        <div class="chat-content">
            <component @request_selected="handle_requested_selected" 
            @back="handle_back_to_request_screen"
            :selected_request="selected_request" :requests="requests"
            :is_session_closed="selected_request_closed_by_client" :is="state"/>
        </div>
    </div>
    
  <!-- just for mail -->
    <!-- <div v-if="current_user" class="form-email" id="form-email" v-show="false">
      <table
        style="width:80%; border-collapse: collapse; font-size: 1.1rem; margin: 1rem 0;"
      >
        <tr style="background-color: #dddddd;">
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            תאריך וזמן
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{ new Date().toLocaleDateString('he') }},{{new Date().toLocaleTimeString('he')}}
          </td>
        </tr>
        <tr>
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            שם המשתמש
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{current_user.name}}
          </td>
        </tr>
        
      </table>
    </div> -->
</template>

<script>
import { computed, onBeforeUnmount, ref } from '@vue/runtime-core'
import {projectFirestore} from '../../../Firebase/config'
import TriggerChat from './TriggerChat.vue'
import store from '../../../store'
import RequestsScreen from './RequestsScreen.vue'
import ChatScreen from './ChatScreen.vue'

export default {
    components:{TriggerChat,RequestsScreen,ChatScreen},
    setup(){
        
        const state = ref('RequestsScreen')
        const selected_request = ref(null)
        const selected_request_closed_by_client = ref(false)

        const current_user = ref(computed(()=>{
            return store.state.user
        }))
        const chat_box = ref()
        const minimize_btn = ref()

        let request_unsub = null
        const requests = ref([])

        const is_show_support_chat = ref(computed(()=>{
            return store.state.admin.show_support_chat
        }))
        
        const handle_back_to_request_screen = ()=>{
            selected_request_closed_by_client.value = false
            state.value = 'RequestsScreen'
        }
        const handle_requested_selected = (request) =>{
            selected_request.value = request
            state.value = 'ChatScreen'
        }
        const handle_close_chat_window = () => {
            toggle_comp()
        }

        const handle_minimize_chat_window = ()=>{
            chat_box.value.classList.toggle('minimize-chat');
            minimize_btn.value.classList.toggle('rotate-icon')
        }

        const toggle_comp = () => {
            store.state.admin.show_support_chat = !store.state.admin.show_support_chat
        }

        const get_requests_RT = ()=>{
            request_unsub = projectFirestore.collection('Support').doc('Pending').collection('PendingRequests').onSnapshot(snapshot => {
                 snapshot.docChanges().forEach((change) => {
                    if (change.type === "added") {
                        requests.value.push(change.doc.data())
                    }
                    if (change.type === "modified") {
                        const index = requests.value.findIndex(request=>request.user_uid==change.doc.data().user_uid)
                        if(index!=-1){
                            requests.value[index]=change.doc.data()
                        }
                    }
                    if (change.type === "removed") {
                        if(selected_request.value && selected_request.value.uid == change.doc.data().uid && change.doc.data().status!='ended'){
                            selected_request_closed_by_client.value = true
                        }
                        const index = requests.value.findIndex(request=>request.user_uid==change.doc.data().user_uid)
                        if(index!=-1){
                            requests.value.splice(index,1)
                        }
                    }
                })
            })
        }

        get_requests_RT()

        onBeforeUnmount(()=>{
            if(request_unsub){
                request_unsub()
            }
        })

        return{
            handle_close_chat_window, 
            handle_minimize_chat_window,
            handle_requested_selected,
            handle_back_to_request_screen,
            chat_box,
            minimize_btn,
            is_show_support_chat,
            current_user,
            state,
            selected_request,
            requests,
            selected_request_closed_by_client,
        }
    }
}
</script>

<style scoped>
    .chat-box{
        position: fixed;
        bottom: 10px;
        right: 10px;
        width: 340px;
        max-width: calc(100% - 10px);
        height: 550px;
        max-height: calc(100% - 20px);
        background: #fff;
        border-radius: 10px;
        overflow: hidden;
        z-index: 1000;
        transition: height 0.2s;
    }
    .chat-header{
        position: relative;
        width: 100%;
        height: 50px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--turkiz);
        color: white;
        font-size: 24px;
        text-shadow: 0 0 3px rgba(0, 0, 0, 0.76);
    }
    .chat-msgs{
        position: relative;
        width: 100%;
        height: calc(100% - 130px);
        overflow:hidden;
        overflow-y: auto;
        padding: 3px;
    }
    .chat-line-service{
        width: fit-content;
        padding: 5px;
        background-color: rgb(223, 221, 221);
        display: flex;
        margin-bottom: 10px;
        flex-wrap: wrap;
        overflow: hidden;
        align-items: center;
        border-radius: 10px;
    }
    .chat-line-client{
        width: fit-content;
        padding: 5px;
        background-color: rgba(97, 231, 255, 0.712);
        display: flex;
        margin-bottom: 10px;
        flex-wrap: wrap;
        align-items: center;
        overflow: hidden;
        border-radius: 10px;
    }
    .chat-input{
        padding: 5px;
        position: relative;
        width: 100%;
        height: 80px;
        display: flex;
        justify-content: space-between;
        flex-shrink: 0;
    }
    .close-btn{
        position: absolute;
        top: 10px;
        left: 5px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--danger);
        cursor: pointer;
    }
    .close-btn:hover i{
        transform: scale(0.95);
    }
    .close-btn i {
        transition: 0.25s;
    }
    .minimize-btn{
        position: absolute;
        top: 10px;
        left: 50px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--turkiz);
        cursor: pointer;
        transition: transform 1s;
    }
    .minimize-btn.rotate-icon{
        transform: rotate(-180deg);
    }
    .chat-box.minimize-chat{
        height: 50px;
        width: 100px;
        background: unset;
    }
    .chat-box.minimize-chat .chat-header{
        justify-content: flex-end;
        font-size: 20px;
        padding: 0 5px;
    }
    .chat-box.minimize-chat .close-btn{
        display: none;
    }
    .chat-box.minimize-chat .minimize-btn{
        left: 5px;
    }
    .chat-box.minimize-chat > div:not(:first-child){
        display: none;
    }

    .chat-content{
        width: 100%;
        height: calc(100% - 50px);
    }

  

</style>