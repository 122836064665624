import { ref, watchEffect } from "vue"
import { projectFirestore } from "../../../../Firebase/config"

const change_status=async(user_uid,status)=>{
    await projectFirestore.collection('Support').doc('Pending')
    .collection('PendingRequests').doc(user_uid)
    .set({
        status
    },{merge:true})
}

const handle_read_msg = async(user_uid,msg_id)=>{
    await projectFirestore.collection('Support').doc('Messages')
    .collection(user_uid).doc(msg_id)
    .set({
        read:true
    },{merge:true})
}


const handle_count_unreaded_msg = ()=>{
    const unreaded_msgs = ref([])
    
    let messages_unsub = ref(null)
    
    const count_unreaded_msg_RT = async(user_uid)=>{
        messages_unsub.value = projectFirestore.collection('Support').doc('Messages').collection(user_uid).onSnapshot(snapshot => {
            snapshot.docChanges().forEach((change) => {
                if (change.type === "added") {
                    if(change.doc.data().type=='client' && !change.doc.data().read){
                        unreaded_msgs.value.push(change.doc.data())
                    }
                }
                if (change.type === "modified") {
                    if(change.doc.data().type=='client' && change.doc.data().read){
                        const index = unreaded_msgs.value.findIndex(msg=>msg.msgId==change.doc.data().msgId)
                        if(index!=-1){
                            unreaded_msgs.value.splice(index,1)
                        }
                    }
                }
                if (change.type === "removed") {
                    const index = unreaded_msgs.value.findIndex(msg=>msg.msgId==change.doc.data().msgId)
                    if(index!=-1){
                        unreaded_msgs.value.splice(index,1)
                    }
                }
            })
        })

        watchEffect((onInvalidate)=>{
            onInvalidate(()=>messages_unsub.value())
        })
    }

    return{
        count_unreaded_msg_RT,
        unreaded_msgs,
        messages_unsub,
    }
}

export {
    change_status,
    handle_read_msg,
    handle_count_unreaded_msg,
}