<template>
  <div @click="handle_trigger_chat" class="admin-trigger-chat slide-in-right">
    <span class="material-symbols-rounded">chat</span>
  </div>
</template>

<script>
import store from '../../../store'

export default {
    setup(){
        
        const handle_trigger_chat = () => {
            store.state.admin.show_support_chat = !store.state.admin.show_support_chat
        }

        return{
            handle_trigger_chat,
        }
    }
}
</script>

<style scoped>
    .admin-trigger-chat{
        position: fixed;
        width: 60px;
        height: 60px;
        background: var(--light_green);
        right: 0;
        bottom: 100px;
        z-index: 100;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        overflow: hidden;
        cursor: pointer;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.075);
        color: #fff;
    }
    .admin-trigger-chat span{
        font-size: 40px;
        color: white;
        text-shadow: 0 0 5px rgba(0, 0, 0, 0.273);
    }
</style>