<template>
  <div v-if="unreaded_msgs.length>0" class="unreaded-msgs-numbers">
    {{unreaded_msgs.length}}
  </div>
</template>

<script>
import { onBeforeUnmount } from '@vue/runtime-core'
import {handle_count_unreaded_msg} from './Scripts/scripts'
export default {
    props:['user_uid'],
    setup(props){
        const {unreaded_msgs,messages_unsub,count_unreaded_msg_RT}  = handle_count_unreaded_msg()
        
        count_unreaded_msg_RT(props.user_uid)

        onBeforeUnmount(()=>{
            if(messages_unsub.value){
                messages_unsub.value()
            }
        })

        return{unreaded_msgs}
    }
}
</script>

<style scoped>
     .unreaded-msgs-numbers{
        position: absolute;
        width: 20px;
        height: 20px;
        top: 0;
        right: 0;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-size: 10px;
        background: var(--danger);
    }
</style>