<template>
  <div class="requests-screen">
    <div class="requests-screen-header">
        <h2>ממתינים לשירות</h2>
        <h4>
            סה"כ  {{pendings_requests.length}} לקוחות
        </h4>
    </div>
    <div v-if="pendings_requests.length>0" class="requests-screen-content">
        <template v-for="request in pendings_requests" :key="request.user_uid">
            <div @click="handle_start_chat(request,index)" class="user-waiting">
                <div class="user-image">
                    <img v-if="request.user_image" :src="request.user_image">
                    <img v-else src="https://firebasestorage.googleapis.com/v0/b/beezi1.appspot.com/o/Icons%2Fprofile_img.jpg?alt=media&token=fc045fd3-39aa-45b3-b74c-7f927d80685b">
                </div>
                <div class="user-wiating-details">
                    <h5>{{request.display_name}}</h5>
                    <h5 :key="timerCount">
                        {{user_time_left(request.createdAt)}}
                    </h5>
                </div>
            </div>
        </template>
    </div>
    <div v-if="pendings_requests.length==0" class="no-request">
        <img src="https://firebasestorage.googleapis.com/v0/b/beezi1.appspot.com/o/Icons%2Ftechnical-support.png?alt=media&token=0585ee2b-7555-4b04-adb5-63b913ad5042">
    </div>
    <div class="requests-screen-footer">
       <div class="requests-screen-footer-header">
        <h2>שיחות פתוחות</h2>
        <h4>
            סה"כ  {{requests_in_session.length}} לקוחות
        </h4>
       </div>
       <div class="requests-screen-footer-content">
        <template v-for="request in requests_in_session" :key="request.user_uid">
            <div @click="handle_start_chat(request)" class="user-in-chat">
                <div class="user-image">
                    <UnreadedMsgsNumbers :user_uid="request.user_uid" />
                    <img v-if="request.user_image" :src="request.user_image">
                    <img v-else src="https://firebasestorage.googleapis.com/v0/b/beezi1.appspot.com/o/Icons%2Fprofile_img.jpg?alt=media&token=fc045fd3-39aa-45b3-b74c-7f927d80685b">
                </div>
                <div class="user-wiating-details">
                    <h5>{{request.display_name}}</h5>
                </div>
            </div>
        </template>
       </div>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'


import { computed, onBeforeUnmount, onMounted} from '@vue/runtime-core'
import {time_diff_calc,get_object_date_with_time} from '../../../Scripts/dates'
import {change_status} from './Scripts/scripts'
import UnreadedMsgsNumbers from './UnreadedMsgsNumbers.vue'

export default {
    emits:['request_selected'],
    props:['requests'],
    components:{UnreadedMsgsNumbers},
    setup(props,context){

        const timerCount = ref(0)
        const unsub_timer = ref(null)

        const pendings_requests = ref(computed(()=>{
            return props.requests.filter(req=>req.status=='pending')
        }))

        const requests_in_session = ref(computed(()=>{
            return props.requests.filter(req=>req.status=='in_session')
        }))

        const handle_start_chat = async(request)=>{
            if(request.status == 'pending'){
                await change_status(request.user_uid,'in_session')
            }
            context.emit('request_selected',request)
        }

        const user_time_left = (createdAt)=>{
            const res = time_diff_calc(new Date(),get_object_date_with_time(createdAt))
            return `D:${res[0]} H:${res[1]} M:${res[2]}` 
        }

        onMounted(()=>{
            unsub_timer.value = setInterval(()=>{
                timerCount.value++
            },1000)
        })

    
        onBeforeUnmount(()=>{
            clearInterval(unsub_timer.value)
        })

        return{
            time_diff_calc,
            get_object_date_with_time,
            user_time_left,
            timerCount,
            handle_start_chat,
            pendings_requests,
            requests_in_session
        }
    }
}
</script>

<style scoped>
    .requests-screen{
        width: 100%;
        height: 100%;
    }
    .requests-screen-header,.requests-screen-footer-header{
        width: 100%;
        height: 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .requests-screen-content{
        width: 100%;
        height: calc(100% - 200px);
        overflow: hidden;
        overflow-y: auto;
        display: grid;
        grid-template-columns: repeat(3,calc(100% / 3));
        grid-auto-rows: 100px;
        grid-gap: 2px;
    }
    .no-request{
        width: 100%;
        height: calc(100% - 200px);
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .no-request img{
        max-width: 70%;
        max-height: 70%;
    }
    .user-waiting{
        width: 100%;
        height: 100%;
        cursor: pointer;
        overflow: hidden;
    }
    .user-image{
        position: relative;
        width: 100%;
        height: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .user-image img{
        max-width: 100%;
        max-height: 100%;
        border-radius: 50%;
        box-shadow: 0 0 2px rgba(0, 0, 0, 0.26);
        transition: transform 0.2s ease-in;
    }
    .user-image img:hover{
        transform: scale(1.2);
    }
    .user-wiating-details{
        width: 100%;
        height: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .requests-screen-footer{
        width: 100%;
        height: 150px;
    }
    .requests-screen-footer-content{
        width: 100%;
        height: 100px;
        display: flex;
         overflow-x: auto;
         padding: 5px 0;
    }
    .user-in-chat{
        width: 100px;
        height: 100%;
        flex-shrink: 0;
        cursor: pointer;
    }

</style>