<template>
  <div class="chat-screen slide-in-left">
    <div class="chat-screen-header">
        <h4 dir="rtl">
            מתקיימת שיחה עם
            {{selected_request.display_name}}
        </h4>
    </div>
    <div class="chat-screen-tools">
        <span @click="$emit('back')" class="material-symbols-rounded icon">forward</span>
        <button v-if="!is_session_closed" @click="handle_finish_session" class="end-session-btn bg-danger">
            סיים שיחה
        </button>
    </div>
    <div ref="chat_screen_msg" class="chat-screen-msgs">
        <template v-for="(msg,index) in sort_messages" :key="msg.msgId">
            <div v-if="index==0" class="messages-date">
                <p>{{display_date_from_iso(msg.createdAt)}}</p>
            </div>
            <div v-if="index>0 && display_date_from_iso(sort_messages[index-1].createdAt) != display_date_from_iso(msg.createdAt)" class="messages-date">
                <p>{{display_date_from_iso(msg.createdAt)}}</p>
            </div>
            <div v-if="msg.type=='supporter'" class="chat-line-service">
                <p style="margin-left:10px; font-size:0.7rem;">{{display_only_time_from_iso(msg.createdAt)}}</p>
                <!-- <p style="font-weight:bold; margin-left:10px;">{{msg.name}}</p> -->
                <p>{{msg.text_msg}}</p>
                <span style="margin: 0 5px;" v-if="msg.read" class="material-symbols-rounded icon-done">done_all</span>
                <span style="margin: 0 5px;" v-if="!msg.read" class="material-symbols-rounded icon-check">check</span>
            </div>
            <div dir="rtl" v-if="msg.type=='client'" class="chat-line-client">
                <p style="margin-left:10px; font-size:0.7rem;">{{display_only_time_from_iso(msg.createdAt)}}</p>
                <p v-if="msg.text_msg">{{msg.text_msg}}</p>
                <img class="snap-shot-img" v-if="msg.image" :src="msg.image">
                <a class="open-image" v-if="msg.image" :href="msg.image" target="_blank"></a>
            </div>
        </template> 
    </div>
    <div v-if="!is_session_closed" class="chat-screen-input">
        <el-input v-model="msg_text" @keypress.enter="handle_msg_text" style="width:75%;" :input-style="{'width': '100%', 'height': '100%', 'font-size': '20px'}" />
        <el-button  @click="handle_msg_text" style="width:23%; height:100%; margin:0; flex-shrink: 0;" type="success">שלח</el-button>
    </div>
    <div v-if="is_session_closed" class="chat-screen-ended-message">
        <p>שיחה נותקה</p>
        <p>{{display_date_and_time_from_iso(get_date_now())}}</p>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import {get_date_now,display_only_time_from_iso,get_object_date_with_time,display_date_from_iso,display_date_and_time_from_iso} from '../../../Scripts/dates'
import { computed, onBeforeUnmount, onUpdated } from '@vue/runtime-core'
import { projectFirestore } from '../../../Firebase/config'
import {alert_confirm} from '../../../Scripts/Msgs'
import {change_status,handle_read_msg} from './Scripts/scripts'

export default {
    emits:['back'],
    props:['selected_request','is_session_closed'],
    setup(props,context){
        const chat_screen_msg = ref()
        const messages = ref([])
        const msg_text = ref('')
        let messages_unsub = null

        const handle_finish_session  = ()=>{
            alert_confirm(`האם לסיים התכתבות עם ${props.selected_request.display_name}`)
            .then(async res=>{
                if(res.isConfirmed){
                    await change_status(props.selected_request.user_uid,'ended')
                    context.emit('back')
                }
            })
        }
        const handle_msg_text = async()=>{
            if(msg_text.value){
                const doc = projectFirestore.collection('Support').doc('Messages').collection(props.selected_request.user_uid).doc()
                await doc.set({
                   createdAt:get_date_now(),
                   msgId:doc.id,
                   name:'תומך',
                   text_msg:msg_text.value,
                   type:'supporter',
                   read:false
                })
                msg_text.value=''
            }
        }

        const sort_messages = ref(computed(()=>{
            if(messages.value.length){
                return messages.value.sort((a,b)=>{
                    return get_object_date_with_time(a.createdAt) - get_object_date_with_time(b.createdAt)
                })
            }
        }))

        const get_messages_RT = (user_uid)=>{
            messages_unsub = projectFirestore.collection('Support').doc('Messages').collection(user_uid).onSnapshot(snapshot => {
                snapshot.docChanges().forEach((change) => {
                    if (change.type === "added") {
                        messages.value.push(change.doc.data())
                        if(change.doc.data().type=='client' && !change.doc.data().read){
                            handle_read_msg(user_uid,change.doc.data().msgId)
                        }
                    }
                    if (change.type === "modified") {
                        const index = messages.value.findIndex(msg=>msg.msgId==change.doc.data().msgId)
                        if(index!=-1){
                            messages.value[index]=change.doc.data()
                        }
                    }
                    if (change.type === "removed") {
                        const index = messages.value.findIndex(msg=>msg.msgId==change.doc.data().msgId)
                        if(index!=-1){
                            messages.value.splice(index,1)
                        }
                    }
                })
            })
        }

        get_messages_RT(props.selected_request.user_uid)

        onUpdated(()=>{
            if(chat_screen_msg.value){
                chat_screen_msg.value.scrollTop = chat_screen_msg.value.scrollHeight;
            }
        })
        onBeforeUnmount(()=>{
            if(messages_unsub){
                messages_unsub()
            }
        })
        
        
        return{
            handle_msg_text,
            display_only_time_from_iso,
            display_date_from_iso,
            display_date_and_time_from_iso,
            get_date_now,
            handle_finish_session,
            chat_screen_msg,
            msg_text,
            sort_messages,
            messages,
        }
    }
}
</script>

<style scoped>
    .chat-screen{
        width: 100%;
        height: 100%;
    }
    .chat-screen-header{
        width:100%;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--grad_bg);
        color: #fff;
    }
    .chat-screen-tools{
        width: 100%;
        height: 30px;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        padding: 0 5px;
        box-shadow: 0 2px 3px -2px gray;
        
    }
    .icon{
        background: var(--turkiz);
        border-radius: 50%;
        color: #fff;
        cursor: pointer;
    }
    .icon-done,.icon-check{
        font-size: 14px;
        color: var(--success);
        align-self: flex-end;
    }
    .icon-check{
       color: var(--dark_bg); 
    }
    .end-session-btn{
        border: 0;
        height: 80%;
        border-radius: 5px;
        padding: 0 5px;
        margin-right: auto;
        cursor: pointer;
    }
    .chat-screen-msgs{
        position: relative;
        width: 100%;
        height: calc(100% - 140px);
        overflow:hidden;
        overflow-y: auto;
        padding: 3px;
        display: flex;
        flex-direction: column;
    }
    .chat-screen-input,.chat-screen-ended-message{
        padding: 5px;
        position: relative;
        width: 100%;
        height: 80px;
        display: flex;
        justify-content: space-between;
        flex-shrink: 0;
    }
    .chat-screen-input{
        flex-direction: row-reverse;
    }
    .chat-line-service,.chat-line-client,.messages-date{
        position: relative;
        width: fit-content;
        padding: 5px;
        background-color: #dcf8c6;
        display: flex;
        margin-bottom: 10px;
        flex-wrap: wrap;
        overflow: hidden;
        align-items: center;
        border-radius: 10px;
        flex-shrink: 0;
    }
    .chat-line-service{
        align-self: flex-end;
        direction: rtl;
    }
    .chat-line-client{
        background-color: rgb(223, 221, 221);
    }
    .chat-screen-ended-message{
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .messages-date{
        width: 100%;
        color: rgb(192, 189, 189);
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1px;
        background-color: unset;
        font-size: 14px;
    }

    .snap-shot-img{
        max-height: 100%;
        max-width: 100%;
    }
    
    .open-image::after{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
</style>